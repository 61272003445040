<template>
  <span>

    <!-- Heading -->
    <h1 class="headline white--text">
      <v-icon
          color="green"
          title="päivitä"
          class="ml-2"
          style="cursor: pointer"
          @click="refreshModels"
      >
        mdi-refresh-circle
      </v-icon>
      Agentit
    </h1>

    <!-- Seacrh options -->
    <v-row>
      <v-col cols="5">
            <v-text-field
                v-model="keyword"
                append-icon="mdi-magnify"
                label="Etsi nimi tai koodi"
                single-line
                hide-details
                clearable
                class="mt-5 mb-10"
            ></v-text-field>
      </v-col>

      <v-col xl="1" lg="2" md="2" sm="2" cols="2">
        <v-checkbox
            v-model="options.show_nonactive"
            label="näytä lopettaneet"
            class="pt-7"
        ></v-checkbox>
      </v-col>

      <v-col xl="1" lg="2" md="2" sm="2" cols="2">
        <v-checkbox
            v-model="options.show_no_tikon"
            label="näytä ilman tikon-nroa"
            class="pt-7"
        ></v-checkbox>
      </v-col>

      <v-col>
        <v-btn
            class="v-btn mt-9 green float-right"
            small
            to="/agent/uusi"
        >
          <v-icon class="mr-2">mdi-plus-circle-outline</v-icon> Lisää agentti
        </v-btn>
      </v-col>

    </v-row>


    <!-- data table -->
    <v-data-table
        :headers="headers"
        :items="models"
        :options.sync="options"
        :server-items-length="totalModels"
        :loading="loading"
        sort-by="started"
        :sort-desc="true"
        class="elevation-1"
        :footer-props="{
          'items-per-page-options':[
              15,
              30,
              50,
              100,
              200,
              ]
          }"
    >

      <!-- name slot (link) -->
      <template slot="item.name" slot-scope="data">
        <router-link :to="'/agent/'+data.item.id+'/main'">
          {{ data.item.name }}
        </router-link>
        <v-icon
            v-if="data.item.busy"
            color="red"
            title="varattu"
            class="ml-2"
        >
          mdi-phone-alert
        </v-icon>
        <v-icon
            v-if="!data.item.active"
            color="red"
            title="ei aktiivisena"
            class="ml-2"
            @click="setActive(data.item.id)"
        >
          mdi-account-cancel
        </v-icon>


        <v-chip-group
            style="max-width: 300px"
            column
            class="mb-4"
        >
          <template v-for="temp in data.item.features">
            <v-chip
                v-if="featureName(temp.id)"
                :key="temp.id"
                x-small
            >
              {{ featureName(temp.id) }}
            </v-chip>
          </template>

        </v-chip-group>
      </template>

      <template slot="item.workstation_login" slot-scope="data">
        <v-icon
            v-if="data.item.workstation_login && data.item.workstation_login == 'Y'"
            color="green"
            title="Kirjautunut työasemalle"
            class="ml-2"
            @click="setActive(data.item.id)"
        >
          mdi-account-cancel
        </v-icon>
      </template>

      <!-- languages (array) -->
      <template slot="item.languages" slot-scope="data">
        <v-chip-group>
            <span
                v-for="lang in data.item.languages"
                :key="lang.id"
            >
              <v-img
                  :src="require('../assets/flags/'+lang.flag)"
                  :alt="lang.name"
                  :title="lang.name"
                  width="15"
                  height="10"
                  style="margin-left: 3px;margin-right: 3px"
              />
            </span>
        </v-chip-group>
      </template>

    </v-data-table>

  </span>
</template>

<script>
// @ is an alias to /srcm
import GlobalVars from "../mixins/GlobalVars";

export default {
  name: 'Agents',
  mixins: [GlobalVars], //import default set of values
  data() {
    return {
      totalModels: 0, //get the value from server
      headers: [ //header settings of the datatable
        {
          text: 'Nimi',
          align: 'start',
          sortable: true,
          value: 'name',
          width: '300'
        },
        { text: 'Online', value: 'workstation_login', sortable: true },
        //{ text: 'A', value: 'active', sortable: true },
        { text: 'Koodi', value: 'agent_code', sortable: true },
        //{ text: 'Tekee', value: 'features', sortable: false, 'max-width': 100 },
        { text: 'Kielet', value: 'languages', sortable: false},
        { text: 'Aloitus', value: 'started' },
        { text: 'Aktiivinen', value: 'last_agent_action_timestamp', sortable: true },
        { text: 'Oli puhelimessa', value: 'logged_in_time', sortable: true },
        { text: 'Oli AV chatissa', value: 'last_adult_alias_login', sortable: true },
        { text: 'Oli AT chatissa', value: 'last_professional_alias_login', sortable: true },
      ],
    }
  },

  computed: {

    apiOrder(){

      //customize into firstname if order is name
      if(this.options.sortBy.length > 0 && this.options.sortBy[0] == 'name') return 'firstname'

      //use started as default if sortBy is empty
      return this.options.sortBy.length > 0 ? this.options.sortBy[0] : 'started'

    }
  },
  methods: {

    setActive(id) {
      //this.axios.put('chat_aliases/'+id, {active: true}).then(()=>{

      this.snackbar_message = "Agentti päivitetty aktiiviseksi"
      this.snackbar = true

      this.models.filter(x => x.id == id)[0].active = true
      this.models.filter(x => x.id == id)[0].not_active_reason = null
      this.models.filter(x => x.id == id)[0].not_active_reason_id = null
      //})
      this.$root.$emit('agent', this.models.filter(x => x.id == id)[0])
    },

    featureName(id){

      switch(id){

        case 1: return 'AV chat'
        case 2: return 'AT chat'
        case 3: return 'AV audio'
        case 4: return 'AT audio'
        case 7: return 'AT Horoskooppi'
        case 8: return 'Verkkotilaus'
        case 10: return 'AV Horoskooppi'
        case 11: return 'Julk. chat'
        case 12: return 'Julk. chat admin'

      }

    },

    //get the required set of models with appropriate set of parameters
    mount(){

      if(this.loading) return //silently fail if loading is already ongoing

      this.loading = true

      //get models
      this.axios.get('agents?' +
          '&skip=' + (this.options.page * this.options.itemsPerPage - this.options.itemsPerPage) +
          '&take=' + this.options.itemsPerPage +
          '&order=' + this.apiOrder +
          '&order_direction=' + (this.options.sortDesc[0] ? 'DESC' : 'ASC') +
          '&keyword=' + this.keyword +
          (this.options.show_nonactive ? '' : '&active=1')+
          (this.options.show_no_tikon ? '&no_tikon=1' : '')
          ).then((response)=>{
          this.models = response.data
          this.loading = false
      }).catch((e) => {

        this.models = []
        this.axiosError(e)

      })

      //also get model count
      this.axios.get('agents/count?' +
          '&keyword=' + this.keyword +
          (this.options.show_nonactive ? '' : '&active=1')+
          (this.options.show_no_tikon ? '&no_tikon=1' : '')
      ).then((response)=>{
        this.totalModels = response.data
      })

    }
  },
  mounted(){

    this.mount()

    //react when agent_update is emitted
    this.$root.$on('agents', function(payload){

      //if agent id is found in array, replace agent with payload. (any downside to this?)
      if(this.models.some(item => item.id == payload.id)){
        console.log('found', this.models.filter(item => item.id == payload.id)[0], payload)
        //this.models.forEach(function(item, i) {if(item.id == payload.id) this.models[i] = payload}.bind(this.models))
        this.models = this.models.map(item => item.id == payload.id ? item = payload : item)
      }else
        this.models.unshift(payload)


    }.bind(this))

  }
}
</script>
<style>
.v-label {
  font-size: 0.8em;
}
</style>
