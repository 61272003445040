var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',[_c('h1',{staticClass:"headline white--text"},[_c('v-icon',{staticClass:"ml-2",staticStyle:{"cursor":"pointer"},attrs:{"color":"green","title":"päivitä"},on:{"click":_vm.refreshModels}},[_vm._v(" mdi-refresh-circle ")]),_vm._v(" Agentit ")],1),_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('v-text-field',{staticClass:"mt-5 mb-10",attrs:{"append-icon":"mdi-magnify","label":"Etsi nimi tai koodi","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}})],1),_c('v-col',{attrs:{"xl":"1","lg":"2","md":"2","sm":"2","cols":"2"}},[_c('v-checkbox',{staticClass:"pt-7",attrs:{"label":"näytä lopettaneet"},model:{value:(_vm.options.show_nonactive),callback:function ($$v) {_vm.$set(_vm.options, "show_nonactive", $$v)},expression:"options.show_nonactive"}})],1),_c('v-col',{attrs:{"xl":"1","lg":"2","md":"2","sm":"2","cols":"2"}},[_c('v-checkbox',{staticClass:"pt-7",attrs:{"label":"näytä ilman tikon-nroa"},model:{value:(_vm.options.show_no_tikon),callback:function ($$v) {_vm.$set(_vm.options, "show_no_tikon", $$v)},expression:"options.show_no_tikon"}})],1),_c('v-col',[_c('v-btn',{staticClass:"v-btn mt-9 green float-right",attrs:{"small":"","to":"/agent/uusi"}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-plus-circle-outline")]),_vm._v(" Lisää agentti ")],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.models,"options":_vm.options,"server-items-length":_vm.totalModels,"loading":_vm.loading,"sort-by":"started","sort-desc":true,"footer-props":{
        'items-per-page-options':[
            15,
            30,
            50,
            100,
            200,
            ]
        }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(data){return [_c('router-link',{attrs:{"to":'/agent/'+data.item.id+'/main'}},[_vm._v(" "+_vm._s(data.item.name)+" ")]),(data.item.busy)?_c('v-icon',{staticClass:"ml-2",attrs:{"color":"red","title":"varattu"}},[_vm._v(" mdi-phone-alert ")]):_vm._e(),(!data.item.active)?_c('v-icon',{staticClass:"ml-2",attrs:{"color":"red","title":"ei aktiivisena"},on:{"click":function($event){return _vm.setActive(data.item.id)}}},[_vm._v(" mdi-account-cancel ")]):_vm._e(),_c('v-chip-group',{staticClass:"mb-4",staticStyle:{"max-width":"300px"},attrs:{"column":""}},[_vm._l((data.item.features),function(temp){return [(_vm.featureName(temp.id))?_c('v-chip',{key:temp.id,attrs:{"x-small":""}},[_vm._v(" "+_vm._s(_vm.featureName(temp.id))+" ")]):_vm._e()]})],2)]}},{key:"item.workstation_login",fn:function(data){return [(data.item.workstation_login && data.item.workstation_login == 'Y')?_c('v-icon',{staticClass:"ml-2",attrs:{"color":"green","title":"Kirjautunut työasemalle"},on:{"click":function($event){return _vm.setActive(data.item.id)}}},[_vm._v(" mdi-account-cancel ")]):_vm._e()]}},{key:"item.languages",fn:function(data){return [_c('v-chip-group',_vm._l((data.item.languages),function(lang){return _c('span',{key:lang.id},[_c('v-img',{staticStyle:{"margin-left":"3px","margin-right":"3px"},attrs:{"src":require('../assets/flags/'+lang.flag),"alt":lang.name,"title":lang.name,"width":"15","height":"10"}})],1)}),0)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }